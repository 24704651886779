import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Fab,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SmartToyIcon from '@mui/icons-material/SmartToy'; // AI robot icon
import api from '../api';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function Chatbot({ currentListId, onItemAdded }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  // Initialize chat with welcome message
  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([{
        role: 'assistant',
        content: "Hello! I'm ListLink AI, your intelligent list management assistant. I can help you:\n\n" +
          "• Create new lists\n" +
          "• Add items to your lists\n" +
          "• Manage and update your lists\n" +
          "• Share lists with others\n\n" +
          "Just tell me what you'd like to do!"
      }]);
    }
  }, [isOpen, messages.length]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = {
      role: 'user',
      content: input,
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      const conversation = [...messages, userMessage];
      const requestBody = {
        messages: conversation,
        ...(currentListId && { currentListId })
      };

      const response = await api.post('/chatbot', requestBody);

      const assistantMessage = {
        role: 'assistant',
        content: response.data.content,
      };

      setMessages((prev) => [...prev, assistantMessage]);

      if (response.data.function_response) {
        const functionResponse = response.data.function_response;
        const functionName = response.data.function_name;

        if (functionResponse.success) {
          if (functionName === 'create_list' && functionResponse.data?.[0]?._id) {
            navigate(`/lists/${functionResponse.data[0]._id}`);
          } else if (functionName === 'add_item_to_list' && onItemAdded) {
            onItemAdded();
          }
        }
      }
    } catch (error) {
      console.error('Error with chatbot:', error);
      setMessages((prev) => [...prev, {
        role: 'assistant',
        content: 'Sorry, there was an error processing your request.',
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const toggleChat = () => setIsOpen((prev) => !prev);

  // Typing indicator component
  const TypingIndicator = () => (
    <Box sx={{
      display: 'flex',
      gap: 1,
      padding: '8px',
      alignItems: 'center'
    }}>
      <SmartToyIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
      <Box sx={{
        display: 'flex',
        gap: 0.5
      }}>
        {[0, 1, 2].map((i) => (
          <Box
            key={i}
            sx={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: 'text.secondary',
              animation: 'bounce 1.4s infinite ease-in-out',
              animationDelay: `${i * 0.16}s`,
              '@keyframes bounce': {
                '0%, 80%, 100%': { transform: 'scale(0)' },
                '40%': { transform: 'scale(1.0)' }
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      {!isOpen && (
        <Fab
          color="primary"
          onClick={toggleChat}
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 1000,
          }}
        >
          <ChatIcon />
        </Fab>
      )}

      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            width: '350px',
            height: '500px',
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            sx={{
              padding: '8px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SmartToyIcon />
              <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                ListLink AI
              </Typography>
            </Box>
            <IconButton
              size="small"
              onClick={toggleChat}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: '8px',
            }}
          >
            <List>
              {messages.map((msg, index) => (
                <ListItem key={index} sx={{ padding: 0 }}>
                  <Paper
                    sx={{
                      padding: '8px',
                      marginBottom: '8px',
                      maxWidth: '80%',
                      marginLeft: msg.role === 'user' ? 'auto' : '0',
                      backgroundColor:
                        msg.role === 'user'
                          ? theme.palette.primary.light
                          : theme.palette.grey[200],
                    }}
                  >
                    <ListItemText
                      primary={msg.content}
                      primaryTypographyProps={{
                        variant: 'body2',
                        sx: {
                          fontSize: '0.9rem',
                          whiteSpace: 'pre-line' // Preserve line breaks
                        },
                      }}
                    />
                  </Paper>
                </ListItem>
              ))}
              {isTyping && (
                <ListItem sx={{ padding: 0 }}>
                  <Paper
                    sx={{
                      marginBottom: '8px',
                      backgroundColor: theme.palette.grey[200],
                    }}
                  >
                    <TypingIndicator />
                  </Paper>
                </ListItem>
              )}
              <div ref={messagesEndRef} />
            </List>
          </Box>

          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Type a message...   (Shift + Enter for new line)"
              fullWidth
              size="small"
              multiline
              maxRows={4}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              InputProps={{
                sx: { fontSize: '0.9rem' },
              }}
              inputRef={inputRef}
            />
            <IconButton color="primary" onClick={handleSend}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Chatbot;